import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../shared/services/transaction.service';
import { AgentService } from '../shared/services/agent.service';
import {RouterModule, Route, ActivatedRoute} from '@angular/router';
import {AdminService} from '../shared/services/admin.service';
import { ExcelService } from '../shared/services/excel.service'

@Component({
  selector: 'app-transaction',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  showFilter: boolean = false;
  showViewer: boolean = false;
  transactions;
  showLoader: boolean = false;
  selectedTransaction: string;
  agents;
  emptyTransactionMessage: string = 'Transaction log is empty.';
  sortType: string;
  sortReverse: boolean = false;
  config: any;
  title;
  details;

  constructor(private transactionService: TransactionService, private route: ActivatedRoute,
              private adminService: AdminService, private excelService: ExcelService) {
  }

  ngOnInit() {
    this.showLoader = false;
    this.showLoader = false;
    this.getDetails();

    this.route.paramMap.subscribe(params => {
      const status = params.get('status');
      if (status && status !== 'today') {
        const edit = (status === 'success') ? 'Successful' : status;
        this.title = edit + ' Transactions';
        this.getTransactionsWithStatus(status);
        console.log(status);
      }
      if (status && status === 'today') {
        this.title = 'Today\'s Transactions';
        this.getTransactionsWithStatus('today');
        console.log(status);
      }
      if (!status) {
          this.getAllTransactions();
      }
    });

  }

  onShowFilter() {
    this.showFilter = true;
  }

  onCloseFilter(data) {
    this.showFilter = data;
  }

  getFilteredTransactions(event) {
    this.transactions = event.data;
    this.emptyTransactionMessage = 'Filter Result: 0 No matching records.';
  }
  isEmptyTransaction() {
    if(Object.keys(this.transactions).length > 0) {
      return false;
    }
    return true;
  }



//   {
//     "transaction_ref": "TRF-3983491946",
//     "sender_fullname": "koko    thiery brand",
//     "sender_mobile_number": "679868074",
//     "sender_country": "CMR",
//     "sender_city": "SDU",
//     "sender_email": null,
//     "beneficiary_fullname": "DESMOND NSAH",
//     "beneficiary_mobile_number": "07038797519",
//     "beneficiary_country": "NGA",
//     "beneficiary_city": "NBA",
//     "beneficiary_email": null,
//     "beneficiary_documents": null,
//     "sender_documents": null,
//     "beneficiary_bank_name": "UNITED BANK FOR AFRICA",
//     "beneficiary_bank_account_number": "2108662382",
//     "beneficiary_bank_account_name": "DESMOND NSAH",
//     "payout_amount": "496858.27",
//     "payout_currency": "NGN",
//     "mode_of_payment": "bank_transfer",
//     "status": "success",
//     "purpose_of_transaction": "family support",
//     "created_at": "2024-07-11 11:46:03",
//     "message": "Transaction was successful",
//     "agent": {
//         "id": 1,
//         "name": "JubaExpress",
//         "email": "callcentre@jubaexpress.com",
//         "country": 200,
//         "state": 20,
//         "address": "oba elegushi",
//         "phone_number": "080",
//         "status": "1",
//         "ipaddress": null,
//         "created_at": "2019-03-28 08:46:22",
//         "updated_at": "2024-08-16 10:49:22",
//         "active_env": "live",
//         "balance": "39694350.00",
//         "slug": "jubaexpress",
//         "webhook": null
//     }
// },




  exportReports() {
   const reports = [];

   this.transactions.forEach(({
transaction_ref,
     sender_fullname,
sender_mobile_number,
sender_country,
sender_city,
sender_email,     beneficiary_fullname,
beneficiary_mobile_number,
beneficiary_country,
beneficiary_city,
beneficiary_email,
beneficiary_bank_name,
beneficiary_bank_account_number,
beneficiary_bank_account_name,
payout_amount,
payout_currency,
mode_of_payment,
status,
    purpose_of_transaction,
created_at
   }) =>  reports.push({
    created_at,
    transaction_ref,
    sender_fullname,
sender_mobile_number,
sender_country,
sender_city,
sender_email,     beneficiary_fullname,
beneficiary_mobile_number,
beneficiary_country,
beneficiary_city,
beneficiary_email,
beneficiary_bank_name,
beneficiary_bank_account_number,
beneficiary_bank_account_name,
payout_amount,
payout_currency,
mode_of_payment,
status,
   purpose_of_transaction})  )


    this.excelService.exportAsExcelFile(reports, 'icashremit report');
  }

  getAllTransactions() {
    this.transactionService.getAll().subscribe(
      data => {
        this.transactions = data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.transactions.length
        };
      }
    );
  }


  

  getTransactionsWithStatus(id) {
    this.transactionService.getTransactionsWithStatus(id).subscribe(
      data => {
        this.transactions = data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.transactions.length
        };
      }
    );
  }



// Sort
  sortOrders(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.transactions.sort(this.dynamicSort(property));
  }
  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return (a, b) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }


  pageChanged(event) {
    this.config.currentPage = event;
  }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      });
  }

}
