import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdminService} from '../../shared/services/admin.service';


@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.css']
})
export class AdminDetailsComponent implements OnInit {
data;
  constructor(private route: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      this.getDetails(slug);
    });
  }

  getDetails(slug) {
    this.adminService.getAdmin(slug).subscribe(
      // data => console.log(data)
      data => this.data = data
    );
  }
  adminLevel($level) {
    if ($level === 'admin') {
      return 'ADMIN';
    }
    return 'SUPER ADMIN';
  }

}
