import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { FundHistoryFilterComponent } from './fund-history-filter/fund-history-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgentModule} from '../agent/agent.module';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularDraggableModule} from 'angular2-draggable';
import { FundHistoryModalComponent } from './fund-history-modal/fund-history-modal.component';
import { FundDetailsComponent } from './fund-details/fund-details.component';

@NgModule({
  declarations: [FundHistoryFilterComponent, FundHistoryModalComponent, FundDetailsComponent],
  imports: [
    CommonModule, SharedModule, AgentModule, RouterModule, FormsModule, NgbModule, AngularDraggableModule, ReactiveFormsModule
  ],
  exports: [FundHistoryFilterComponent, FundHistoryModalComponent, FundDetailsComponent]
})
export class FundHistoryModule { }
