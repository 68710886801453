import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthUserHelper } from '../helpers/auth-user.helper';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authUserHelper: AuthUserHelper, private router: Router) { }

  canActivate() {
    if (!this.authUserHelper.isAuthenticated()) {
        this.router.navigate(['/login']);
        return false;
    }
    return true;
  }
}
