import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../shared/services/admin.service';
import {NavigationEnd, Router} from '@angular/router';
import {AgentService} from '../shared/services/agent.service';
import {HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit, OnDestroy {
  showFilter: boolean = false;
  showViewer: boolean = false;
  transactions;
  showLoader: boolean = false;
  selectedTransaction: string;
  emptyAdminMessage: string = 'No Admin created.';
  sortType: string;
  sortReverse: boolean = false;

  admins;
  dateVal: string = null;
  config: any;
  navigationSubscription;
  response;
  responseTwo;

  constructor(private adminService: AdminService, private router: Router, private toastrService: ToastrService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initialiseData();
      }
    });
  }
  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.adminService.get().subscribe(
      data => {
        this.admins = data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.admins.length
        };
      }
    );
  }

  initialiseData() {
    this.getAll();
    // Set default values and re-fetch any data you need.
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  dateSelected(val) {
    this.dateVal = val;
  }

  getStatus(status) {
    return status === 1;
  }
  // Filter
  onShowFilter() {
    this.showFilter = true;
  }

  onCloseFilter(data) {
    this.showFilter = data;
  }

  onShowViewer(value) {
    this.showViewer = true;
    this.selectedTransaction = value;
  }

  onCloseViewer(close) {
    this.showViewer = close ? false : true;
  }

  getFilteredTransactions(event) {
    this.admins = event.data;
    this.emptyAdminMessage = 'Filter Result: 0 No matching records.';
  }
  isEmptyTransaction() {
    if (Object.keys(this.admins).length > 0) {
      return false;
    }
    return true;
  }


// Sort
  sortOrders(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.admins.sort(this.dynamicSort(property));
  }
  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return (a, b) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  onClick(event, slug) {
    const status = (event === true) ? '1' : '0';
    this.adminService.changeStatus({status , slug}).subscribe(
      data => {
        this.response = data;
        // this.responseTwo = responseArray[1];
        if (this.response.status) {
          this.toastrService.success('Live ' + this.response.message);
        } else {
          this.toastrService.error(this.response.message);
        }
        // if (this.responseTwo.status) {
        //   this.toastrService.success('Test ' + this.responseTwo.message);
        // } else {
        //   this.toastrService.error(this.responseTwo.message);
        // }

      },
      (error: HttpResponse<any>) => {
        if (error.status === 404) {
          this.toastrService.error('An Unexpected Error just occurred');
        } else if (error.status === 422) {
          this.toastrService.error('Unprocessable Entity');
        } else {
          this.toastrService.error(error.statusText);
        }
        console.log(error);
      });
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
}
