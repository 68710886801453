import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {AuthenticationService} from '../../services/authentication.service';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})

export class TopbarComponent implements OnInit {
  details;

  ngOnInit() {
    this.getDetails();
  }
  constructor(private adminService: AdminService, private authenticationService: AuthenticationService, private router: Router) { }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
        this.checkPage();
      });
  }

  checkPage() {
    if (this.details.permission === 'admin') {
      const page = this.router.url;
      // console.log(page);
      if (page === '/agent' || page === '/admin') {
        this.router.navigate(['dashboard']);
      }
    }
  }

  logout(event) {
    event.preventDefault();
    this.authenticationService.logout();
  }
}
