import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { BottombarComponent } from './components/bottombar/bottombar.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import {AuthUserHelper} from './helpers/auth-user.helper';
import { AuthenticationService } from './services/authentication.service';
import { AgentService } from './services/agent.service';
import { TransactionService } from './services/transaction.service';
// import {ExcelService} from './services/excel.service';
import {GraphService} from './services/graph.service';
import {BankService} from './services/bank.service';
import { UiSwitchModule } from '../../../node_modules/ngx-toggle-switch';
import {AdminService} from './services/admin.service';
import {FirstwordPipe} from './components/topbar/firstword.pipe';


// C:\Users\lawal\icash-angular-admin\node_modules\ngx-toggle-switch

@NgModule({
  declarations: [SidebarComponent, TopbarComponent, BottombarComponent, FirstwordPipe],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule, UiSwitchModule
  ],
  exports: [
    SidebarComponent, TopbarComponent, BottombarComponent,
  ],
  providers: [AuthUserHelper, AuthGuardService,  AuthenticationService, AgentService,
    TransactionService, GraphService, BankService, AdminService]
})


export class SharedModule { }
