import {Component, Input, OnInit} from '@angular/core';
import {TransactionService} from '../../shared/services/transaction.service';
import * as Chart from 'chart.js';
import {GraphService} from '../../shared/services/graph.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
data;
  constructor(private transactionService: TransactionService, private graphService: GraphService) { }

  lineChart;
  Label: any[];
  Paid: number[];
  Failed: number[];
  Added: number[];
  myDoughnutChart;
  txt;
  ngOnInit() {
    this.transactionService.statistics().subscribe(
      data => {
        this.data = data;
        const canvas: any =  document.getElementById('myDoughnutChartCanvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        const dataset = {
          datasets: [
            {
            data: [this.data.new_count, this.data.successful_count, this.data.failed_count, this.data.processing_count],
            backgroundColor: [
              '#cccccc',
              '#57AF5A',
              '#FF4961',
              '#F1E3A4'
            ],
            hoverBackgroundColor: [
              '#9E9E9E',
              '#208a27',
              '#FC0021',
              '#F1C40F'
            ],
          }],
          labels: [
            'New',
            'Successful',
            'Failed',
            'Processing'
          ],
        };
        this.myDoughnutChart = new Chart(ctx, {
          type: 'doughnut',
          data: dataset
        });
      }
    );
  }


}
