import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {EmailValidators} from '../../shared/validators/email.validators';
import {PasswordValidators} from '../../shared/validators/password.validators';
import {ToastrService} from 'ngx-toastr';
import {AdminService} from '../../shared/services/admin.service';
import {AgentService} from '../../shared/services/agent.service';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {AutocompleteService} from '../../shared/services/autocomplete.service';
import {FundHistoryService} from '../../shared/services/fund-history.service';
import {CurrencyService} from '../../shared/services/currency.service';

@Component({
  selector: 'app-fund-history-modal',
  templateUrl: './fund-history-modal.component.html',
  styleUrls: ['./fund-history-modal.component.css']
})
export class FundHistoryModalComponent implements OnInit {
  passwordType: string = 'password';
  passwordShown: boolean = false;
  icon: string = 'fa fa-eye-slash';
  form;
  status;
  response;
  responseTwo;
  searching = false;
  searchFailed = false;
  results: any;
  clicked: boolean = false;
  currencies;
  code;

  @Output('newAgents') newAgent = new EventEmitter();
  @Input('agent') agents;
  constructor(fb: FormBuilder, private adminService: AdminService, private agentService: AgentService,
              private toastrService: ToastrService, private router: Router,  private autocompleteService: AutocompleteService,
              private fundHistoryService: FundHistoryService, private currencyService: CurrencyService) {
    this.form = fb.group({
      agent: ['', Validators.required],
      currency_id: ['', Validators.required],
      exchange_rate: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('[0-9]+([,.][0-9]+)?$')]
      ],
      amount_in_usd: ['', [
        Validators.required,
        Validators.pattern('[0-9]+([,.][0-9]+)?$')]
      ],
       amount_in_naira: ['', [
        Validators.required,
        Validators.pattern('[0-9]+([,.][0-9]+)?$')]
      ],
    });
  }
  ngOnInit() {
    this.getAllAgents();
    this.getAllCurrencies();
  }

  addFund() {
    this.clicked = true;
    this.fundHistoryService.uploadFund(this.form.value).subscribe(
      data => {
        this.response = data;
        if (this.response.status) {
          this.closeModal();
          this.router.navigate(['fund-history']);
          this.toastrService.success(this.response.message);
        } else {
          this.toastrService.error(this.response.message);
        }
        this.clicked = false;
      },
      (error: Response) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );
  }

  closeModal() {
    const modalClose: HTMLElement = document.getElementById('closeModal') as HTMLElement;
    modalClose.click();
  }

  get amount_in_naira() {
    return this.form.get('amount_in_naira');
  }
  get amount_in_usd() {
    return this.form.get('amount_in_usd');
  }
  get exchange_rate() {
    return this.form.get('exchange_rate');
  }
  get agent() {
    return this.form.get('agent');
  }
  get currency() {
    return this.form.get('currency_id');
  }

  generateNaira() {
    const naira = this.calculateAmountInNaira();
    this.form.get('amount_in_naira').setValue(naira);
  }

  setCode() {
    const currencyId = this.form.get('currency_id').value;
    this.currencyService.getById(currencyId).subscribe(
      data => {
        this.code =  data;
      }
    );
  }

  calculateAmountInNaira() {
   const usd = this.form.get('amount_in_usd').value;
   const exchangeRate = this.form.get('exchange_rate').value;
   return parseFloat(usd) * parseFloat(exchangeRate);
  }

  getAllAgents() {
    this.agentService.get().subscribe(
      data => this.agents = data
    );
  }

  getAllCurrencies() {
    this.currencyService.get().subscribe(
      data => this.currencies = data
    );
  }

}
