import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router'

class ForgotPasswordModel {
  email: string;
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Correctly declared as a class property

  model = new ForgotPasswordModel();
  @ViewChild('resetPasswordForm', { static: false }) form;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  clicked: boolean = false;
  token:string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
   // Get URL parameter directly (snapshot method)
  this.token = this.route.snapshot.paramMap.get('token');
  }

  isValidEmail(email: string): boolean {
    return this.emailRegex.test(email);
  }

  onSubmit() {
    // console.log("Form data:", this.model);

    // console.log("Form data:", !this.isValidEmail(this.model.email));

    if (!this.isValidEmail(this.model.email)) {
      this.errorMessage = "Please enter a valid email address.";
      return;
    }

    if (this.model.password !== this.model.confirmPassword) {
      this.errorMessage = "Passwords do not match";
      return;
    }

    this.clicked = true;
    this.errorMessage = null;

    this.authenticationService.resetPassword(this.form.value, this.token).subscribe(
      response => {
        this.clicked = false;
        this.successMessage = 'A confirmation email has been sent to you, you can now login to the platform';
        this.toastr.success('', this.successMessage);
        return this.router.navigate(['/login']);
      },
      (error: HttpResponse<any>) => {
        this.errorMessage = error.statusText;
        this.toastr.error('', this.errorMessage);
        console.log(error);
        this.clicked = false;
      }
    );
  }
}
