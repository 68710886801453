import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from '../../../node_modules/ngx-toggle-switch';
import { AgentDetailsComponent } from './agent-details/agent-details.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import { AgentFilterComponent } from './agent-filter/agent-filter.component';
import { AgentModalComponent } from './agent-modal/agent-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import { UniqueEmailValidatorDirective } from './unique-email-validator.directive';
import {NgxPaginationModule} from 'ngx-pagination';
import {AngularDraggableModule} from 'angular2-draggable';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [AgentDetailsComponent, AgentFilterComponent, AgentModalComponent, UniqueEmailValidatorDirective],
  imports: [
    CommonModule, UiSwitchModule, SharedModule, RouterModule, ReactiveFormsModule, NgxPaginationModule, AngularDraggableModule,
    NgbModule
  ],
  exports: [AgentDetailsComponent, AgentFilterComponent, AgentModalComponent, UniqueEmailValidatorDirective]
})
export class AgentModule { }
