import { Injectable } from '@angular/core';

@Injectable()
export class AuthUserHelper{

    constructor() { }

    setToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    setTokenTwoo(token: string) {
      sessionStorage.setItem('token__', token);
    }

    setTokenTwo(token: string) {
        sessionStorage.setItem('token_', token);
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    getTokenTwo() {
        return sessionStorage.getItem('token_');
    }

    getTokenTwoo() {
        return sessionStorage.getItem('token__');
    }

    removeToken() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token_');
        sessionStorage.removeItem('token__');
    }

    isAuthenticated( ) {
        if ( this.getToken()) {
          return true;
        }
        return false;
    }
}
