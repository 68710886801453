import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }
  @Input('count') count;
  @Input('title') title: string;
  @Input('amount') amount;
  @Input('class') class: string;

}
