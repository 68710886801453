import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDetailsComponent } from './admin-details/admin-details.component';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {DatePickerModule} from 'date-picker-directive';
import {SharedModule} from '../shared/shared.module';
import { AdminFilterComponent } from './admin-filter/admin-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AdminModalComponent } from './admin-modal/admin-modal.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminUniqueEmailDirective } from './admin-unique-email.directive';
import {RouterModule} from '@angular/router';
import {AngularDraggableModule} from 'angular2-draggable';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AdminDetailsComponent, AdminFilterComponent, AdminModalComponent, AdminProfileComponent, AdminUniqueEmailDirective],
  imports: [
    CommonModule, DatePickerModule, SharedModule, FormsModule, UiSwitchModule, ReactiveFormsModule,
    RouterModule, AngularDraggableModule, NgbModule
  ], providers: [],
  exports: [AdminDetailsComponent, AdminFilterComponent, AdminModalComponent, AdminProfileComponent, AdminUniqueEmailDirective]
})
export class AdminModule { }
