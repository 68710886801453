import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StatModule} from './stat/stat.module';
import { GraphComponent } from './graph/graph.component';
import { PieComponent } from './pie/pie.component';


@NgModule({
  declarations: [GraphComponent, PieComponent],
  imports: [
    CommonModule, StatModule
  ],
  exports: [GraphComponent, PieComponent]
})
export class DashboardModule { }
