import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminService} from '../../shared/services/admin.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {AutocompleteService} from '../../shared/services/autocomplete.service';
import {HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';


class FilterModel {
  name: string;
  state: string;
  country: string;
  status: string;
  date;
}
@Component({
  selector: 'app-admin-filter',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.css']
})
export class AdminFilterComponent implements OnInit {
  @Output('closeFilter') closeFilter = new EventEmitter();
  @Output('filteredTransactions') filteredTransaction = new EventEmitter();
  @Output('onFormReset') onFormReset = new EventEmitter();

  model = new FilterModel();
  @ViewChild('filterForm', {static: false}) form;
  display: boolean = false;
  searching = false;
  searchFailed = false;
  results: any;

  constructor(private adminService: AdminService,  private autocompleteService: AutocompleteService, private toastrService: ToastrService) {
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchCountry(term)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )
  searchState = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchState(term, this.form.value.country)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )

  ngOnInit() {
  }

  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }
  onToggle() {
    this.display = !this.display;
  }

  onFilter() {
    this.adminService.filter(this.form.value).subscribe(
      data => {
        this.filteredTransaction.emit(data);
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
      }
    );
  }

  resetFilter() {
    this.form.reset();
    this.onFormReset.emit(true);
  }

}
