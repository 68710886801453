import { Directive } from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdminService} from '../shared/services/admin.service';

@Directive({
  selector: '[appAdminUniqueEmail]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: AdminUniqueEmailDirective, multi: true}]
})
export class AdminUniqueEmailDirective implements AsyncValidator {

  constructor(private adminService: AdminService) { }
  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.adminService.existsEmail({email: control.value}).pipe(
      map(admins => {
        // console.log(control.value);
        return (admins) ? {uniqueEmail: true} : null;
      })
    );
  }


}
