import { Component, OnInit } from '@angular/core';
import {AgentService} from '../shared/services/agent.service';
import {NavigationEnd, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FundHistoryService} from '../shared/services/fund-history.service';
import {AdminService} from '../shared/services/admin.service';

@Component({
  selector: 'app-fund-history',
  templateUrl: './fund-history.component.html',
  styleUrls: ['./fund-history.component.css']
})
export class FundHistoryComponent implements OnInit {
  showFilter: boolean = false;
  showViewer: boolean = false;
  transactions;
  funds;
  emptyHistoryMessage: string = 'Fund History is empty.';
  sortType: string;
  sortReverse: boolean = false;
  config: any;
  title;
  navigationSubscription;
  status;
  response;
  isLive;
  details;

  constructor(private adminService: AdminService, private router: Router, private toastrService: ToastrService,
              private fundHistory: FundHistoryService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initialiseHistories();
      }
    });
  }

  ngOnInit() {
    this.getDetails();
    this.getAll();
  }

  initialiseHistories() {
    this.getAll();
  }
  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      });
  }

  getAll() {
    this.fundHistory.getAll().subscribe(
      data => {
        this.funds = data;
        this.config = {
          id: 1,
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: data.length
        };
      }
    );
  }

  getFilteredHistorys(event) {
    this.funds = event.data;
    this.emptyHistoryMessage = 'Filter Result: 0 No matching records.';
  }
  onShowFilter() {
    this.showFilter = true;
  }

  onCloseFilter(data) {
    this.showFilter = data;
  }


// Sort
  sortOrders(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.funds.sort(this.dynamicSort(property));
  }
  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return (a, b) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }


  pageChanged(event) {
    this.config.currentPage = event;
  }

}
