import {ValidationErrors, AbstractControl} from '@angular/forms';

export class PasswordValidators {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0)
      return {cannotContainSpace: true};
    return null;
  }

  static passwordsShouldMatch(control: AbstractControl) {
    const password = control.get('password');
    const confirmPassword = control.get('cpassword');
      if (password.value !== confirmPassword.value) {
        return {passwordsShouldMatch: true};
      } else {
        return null;
      }
  }
}
