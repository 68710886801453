import { Directive } from '@angular/core';
import {AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AgentService} from '../shared/services/agent.service';

@Directive({
  selector: '[appUniqueEmailValidator]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailValidatorDirective, multi: true}]
})
export class UniqueEmailValidatorDirective implements AsyncValidator {

  constructor(private agentService: AgentService) { }
  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.agentService.existsEmail({email: control.value}).pipe(
      map(agents => {
        // console.log(control.value);
        return (agents) ? {uniqueEmail: true} : null;
      })
    );
  }

}
