import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AdminService} from '../shared/services/admin.service';
import {ToastrService} from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import {EmailValidators} from '../shared/validators/email.validators';
import {HttpResponse} from '@angular/common/http';
import {AgentService} from '../shared/services/agent.service';

@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.css']
})

export class WebhookComponent implements OnInit, OnDestroy {
  data;
  form;
  status;
  navigationSubscription;
  edit = {
    isEdit: false
  };
  clicked: boolean = false;
  option;
  passwordType: string = 'password';
  passwordShown: boolean = false;
  icon: string = 'fa fa-eye-slash';
  ngOnInit() {
    this.getDetails();
  }
  constructor(fb: FormBuilder, private adminService: AdminService, private agentService: AgentService, private toastrService: ToastrService, private router: Router) {
    this.form = fb.group({
      webhook: ['', [Validators.required, Validators.minLength(6)]],
      hash_verification: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    this.getDetails();
    // Set default values and re-fetch any data you need.
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  togglePassword() {
    if (this.passwordShown === true) {
      this.passwordShown = false;
      this.passwordType = 'password';
      this.icon = 'fa fa-eye-slash';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';
      this.icon = 'fa fa-eye';
    }
  }

  updateAdmin() {
    this.clicked = true;
    this.agentService.updateWebHook(this.form.value).subscribe(
      data => {
        this.status = data.status;
        this.clicked = false;
        if (data.status) {
          this.toastrService.success(data['message']);
          this.onClick();
          this.router.navigate(['webhook']);
        } else {
          this.toastrService.error(data['message']);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );
  }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.data = data[0].agent;
        this.setDetails(this.data);
        this.option = this.data.webhook === null ? 'ADD NEW' : 'EDIT';
      });
  }

  setDetails(data) {
    this.form.get('webhook').setValue(data.webhook);
    // this.form.get('hash_verification').setValue('Y');
  }

  onClick() {
    this.edit.isEdit = !this.edit.isEdit;
  }

  get webhook() {
    return this.form.get('webhook');
  }

  get hash_verification() {
    return this.form.get('hash_verification');
  }

}

