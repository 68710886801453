import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import {SharedModule} from '../shared/shared.module';
import {AgentModule} from '../agent/agent.module';
import {RouterModule} from '@angular/router';
import { TransactionFilterComponent } from './transaction-filter/transaction-filter.component';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularDraggableModule} from 'angular2-draggable';

@NgModule({
  declarations: [TransactionDetailsComponent, TransactionFilterComponent],
  imports: [
    CommonModule, SharedModule, AgentModule, RouterModule, FormsModule, NgbModule, AngularDraggableModule
  ],
  exports: [TransactionFilterComponent]
})
export class TransactionsModule { }
