import {Component, OnDestroy, OnInit} from '@angular/core';
import {AgentService} from '../shared/services/agent.service';
import {NavigationEnd, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit, OnDestroy {
agents;
  showFilter: boolean = false;
  showViewer: boolean = false;
  // transactions;
  showLoader: boolean = false;
  selectedTransaction: string;
  emptyAgentMessage: string = 'No IMTO registered.';
  sortType: string;
  sortReverse: boolean = false;
  config: any;
  navigationSubscription;
  status;
  response;
  isLive;
  constructor(private agentService: AgentService, private router: Router, private toastrService: ToastrService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    this.getAll();
  }

  initialiseInvites() {
    this.getAll();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }


  getAll() {
    this.agentService.get().subscribe(
      data => {
        console.log(data);
        this.agents = data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: data.length
        };
      }
    );
  }

  getStatus(status) {
    return this.status = status === 1;
  }

  onClick(event, id) {
    const status = (event === true) ? '1' : '0';
    this.agentService.changeStatus({status , id}).subscribe(
      data => {
        this.response = data;
        if (this.response.status) {
          this.toastrService.success(this.response.message);
        } else {
          this.toastrService.error(this.response.message);
        }

    },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
      });
  }
  onShowFilter() {
    this.showFilter = true;
  }

  onCloseFilter(data) {
    this.showFilter = data;
  }

  onShowViewer(value) {
    this.showViewer = true;
    this.selectedTransaction = value;
  }

  onCloseViewer(close) {
    this.showViewer = !close;
  }

  getFilteredAgents(event) {
    this.agents = event.data;
    this.emptyAgentMessage = 'Filter Result: 0 No matching records.';
  }
  getAddedAgent(event) {
    this.agents.push(event);
  }
  isEmptyAgent() {
    if (Object.keys(this.agents).length > 0) {
      return false;
    }
    return true;
  }


// Sort
  sortOrders(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.agents.sort(this.dynamicSort(property));
  }
  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return (a, b) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
}
