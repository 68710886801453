import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {AdminService} from '../../services/admin.service';
import {AuthUserHelper} from '../../helpers/auth-user.helper';
// declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  details;
  isLive: boolean;
  tooltip;
  env = {
    title:  localStorage.getItem('title') !== 'Test' ? 'Live' : 'Test',
    isLive: localStorage.getItem('title') !== 'Test'
  };
  constructor(private authenticationService: AuthenticationService, private adminService: AdminService, private authUser: AuthUserHelper,
    ) { }

  ngOnInit(): void {
    this.getDetails();
  }

  logout(event) {
    event.preventDefault();
    this.authenticationService.logout();
  }
  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
    });
  }
  onClick() {
    this.env.isLive = !this.env.isLive;
    this.switchUrl();
    document.location.reload();
  }
  disableButton() {
    if (this.details !== undefined ) {
      const disable = (this.details.permission === 'admin' && this.details.agent.active_env === 'test');
      this.env.isLive = disable === true ? false : this.env.isLive;
      this.tooltip = '';
      if (disable === true) {
          this.switchUrl();
          this.tooltip = 'Please Contact the customer representative to register your IMTO to Live Environment';
      }
      return disable;
    }
    return true;
  }

  switchUrl() {
    if (this.env.isLive === false) {
      this.env.title = 'Test';
      localStorage.setItem('url', 'https://test.icashremit.com/v1/');
      // localStorage.setItem('url', 'http://localhost:8000/v1/');
      localStorage.setItem('title', 'Test');
    } else {
      this.env.title = 'Live';
      localStorage.setItem('url', 'https://api.icashremit.com/v1/');
      // localStorage.setItem('url', 'http://localhost:8080/v1/');
      localStorage.setItem('title', 'Live');
    }
    this.setToken();
    // console.log(localStorage.getItem('title'));
  }

  setToken() {
    const token = this.authUser.getToken();
    const token_ = this.authUser.getTokenTwo();
    const token__ = this.authUser.getTokenTwoo();
    const newToken = (token === token_) ? token__ : token_;
    this.authUser.setToken(newToken);
  }
}
