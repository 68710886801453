import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FundHistoryService} from '../../shared/services/fund-history.service';
import {HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {AdminService} from "../../shared/services/admin.service";


@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.css']
})
export class FundDetailsComponent implements OnInit {
  data;
  response;
  details;
  constructor(private route: ActivatedRoute, private fundHistoryService: FundHistoryService, private toastrService: ToastrService,
              private router: Router, private adminService: AdminService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      console.log(id);
      this.getDetails(id);
      this.getAdminDetails();
    });
  }

  getDetails(id) {
    this.fundHistoryService.getFund(id).subscribe(
      data => {
        this.data = data['data'][0];
        console.log( this.data);
      }
    );
  }

  getAdminDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      });
  }
  revertFund(id) {
    this.fundHistoryService.revertFund(id).subscribe(
      data => {
        this.response = data;
        if (this.response.status) {
          this.toastrService.success(this.response.message);
        } else {
          this.toastrService.error(this.response.message);
        }
        this.router.navigate(['fund-history/']);
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
      });
  }

}
