import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import {GraphService} from '../../shared/services/graph.service';
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {
chart;
  lineChart;
  Label: any[];
  Paid: number[];
  Failed: number[];
  Added: number[];
  txt;
  constructor(private graphService: GraphService) { }

  ngOnInit() {
    this.getMyLabel();
  }
  getMyLabel() {
    this.graphService.getLabel().subscribe(
      data => {
        this.Added = data.Added;
        this.Paid = data.Paid;
        this.Failed = data.Failed;
        this.Label = data.Label;
      },
      error => { console.log('Error while fetching'); },
      () => {
        const canvas: any =  document.getElementById('lineChartCanvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        this.lineChart = new Chart(ctx, {
          type: 'line',
          options: {
            legend: {labels: { fontColor: '#444', fontSize: 12}},
            scales: {
              xAxes: [{
                display: true,
                gridLines: {
                  color: '#eee'
                }
              }],
              yAxes: [{
                display: true,
                gridLines: {
                  color: '#eee'
                }
              }],
            },
          },
          data: {
            labels: this.Label,
            datasets: [
              {
                label: 'Daily Paidout transactions',
                data: this.Paid,
                fill: false,
                spanGaps: true,
                pointRadius: [3, 3],
                borderColor: 'green',
              },
              {
                label: 'Daily Added transactions',
                data: this.Added,
                fill: false,
                borderColor: 'yellow',
                pointRadius: [2, 2],
              },
              {
                label: 'Daily Failed transactions',
                data: this.Failed,
                fill: false,
                borderColor: 'red',
              }
            ]
          }
        });
      }
    );
  }

}
