import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { TransactionService } from '../../shared/services/transaction.service';
import {AgentService} from '../../shared/services/agent.service';
import {BankService} from '../../shared/services/bank.service';
import {FormBuilder} from '@angular/forms';
import {debounceTime, switchMap, tap, finalize, distinctUntilChanged, catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AutocompleteService} from '../../shared/services/autocomplete.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FundHistoryService} from '../../shared/services/fund-history.service';
import {AdminService} from "../../shared/services/admin.service";

class FilterModel {
  agent: number;
  amount_in_naira: string;
  amount_in_usd: string;
  exchange_rate: string;
  date;
}
@Component({
  selector: 'app-fund-history-filter',
  templateUrl: './fund-history-filter.component.html',
  styleUrls: ['./fund-history-filter.component.css']
})
export class FundHistoryFilterComponent implements OnInit {
  @Output('closeFilter') closeFilter = new EventEmitter();
  @Output('filteredHistorys') filteredHistory = new EventEmitter();
  @Output('onFormReset') onFormReset = new EventEmitter();

  model = new FilterModel();
  display: boolean = false;
  clicked: boolean = false;
  details;

  @ViewChild('filterForm', {static: false}) form;

  constructor(fb: FormBuilder, private transactionService: TransactionService,
              private agentService: AgentService, private bankService: BankService,
              private autocompleteService: AutocompleteService, private adminService: AdminService,
              private fundHistoryService: FundHistoryService) {
    this.form = fb.group({
      bank: [],
      transaction_ref: [],
      sender: [],
      date: [],
      payment_method: [],
      status: []
    });

  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.details = data[0];
      });
  }

  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }
  onToggle() {
    this.display = !this.display;
  }

  onFilter() {
    this.clicked = true;
    this.fundHistoryService.filter(this.form.value).subscribe(
      data => {
        this.clicked = false;
        this.filteredHistory.emit(data);
      }
    );
  }

  resetFilter() {
    this.form.reset();
    this.onFormReset.emit(true);
  }

}
