import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AgentService} from '../../shared/services/agent.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {AutocompleteService} from '../../shared/services/autocomplete.service';
import {ToastrService} from 'ngx-toastr';
import {HttpResponse} from '@angular/common/http';

class FilterModel {
  name: string;
  state: string;
  country: string;
  status: string;
  date;
}

@Component({
  selector: 'app-agent-filter',
  templateUrl: './agent-filter.component.html',
  styleUrls: ['./agent-filter.component.css']
})
export class AgentFilterComponent implements OnInit {
  @Output('closeFilter') closeFilter = new EventEmitter();
  @Output('filteredAgents') filteredAgent = new EventEmitter();
  @Output('onFormReset') onFormReset = new EventEmitter();

  model = new FilterModel();
  @ViewChild('filterForm', {static: false}) form;
  display: boolean = false;
  searching = false;
  searchFailed = false;
  results: any;

  constructor(private agentService: AgentService,  private autocompleteService: AutocompleteService, private toastrService: ToastrService) {
  }

  ngOnInit() {
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchCountry(term)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )
  searchState = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchState(term, this.form.value.country)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )


  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }
  onToggle() {
    this.display = !this.display;
  }
  onFilter() {
    this.agentService.filter(this.form.value).subscribe(
      data => {
        this.filteredAgent.emit(data);
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
      }
    );
  }

  resetFilter() {
    this.form.reset();
    this.onFormReset.emit(true);
  }

}
