import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AgentService {
  url;
  sideUrl;
  constructor(private http: HttpClient ) {
    this.url = environment.baseApiUrl + 'admin/';
    this.sideUrl = this.getSide() + 'admin/'; // For All conditions Test
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  getSide() {
    if (localStorage.getItem('title') && localStorage.getItem('title') === 'Test') {
      // return 'http://localhost:8000/v1/';
      return 'https://api.icashremit.com/v1/';
    }
    return 'https://test.icashremit.com/v1/';
    // return 'http://localhost:8080/v1/';
  }


  get() {
    return this.http.get(environment.baseApiUrl + 'agents').pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  getTest() {
    return this.http.get(environment.baseApiUrlTest + 'agentTest').pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  getAgent(slug) {
    return this.http.get(environment.baseApiUrl + 'agent/' + slug ).pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }
  getTestAgent(id) {
    return this.http.get(environment.baseApiUrlTest + 'agent/' + id ).pipe(
      map((response: HttpResponse<any>) => {
        console.log(response['data']);
        return response['data'];
      })
    );
  }
  getAgentTransaction(agentSlug) {
    return this.http.get(environment.production ? environment.baseApiUrl + 'agent_transaction/' + agentSlug : environment.baseApiUrlTest + 'agent_transaction/' + agentSlug ).pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  filter(data) {
    return this.http.post(environment.production ? environment.baseApiUrl  + 'agents/filter' : environment.baseApiUrlTest + 'agents/filter', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  addAgent(data) {
    return this.http.post(environment.production ? environment.baseApiUrl + 'agent/register' : environment.baseApiUrlTest + 'agent/register', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  addAgentToLive(data) {
    return this.http.post(environment.production ? environment.baseApiUrl + 'agent/register' : environment.baseApiUrlTest + 'agent/register', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  changeStatus(data) {
    return this.http.post(environment.production ? environment.baseApiUrl  + 'agent/status' : environment.baseApiUrlTest + 'agent/status', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(data);
          return response;
        }
      )
    );
  }

  changeTestEnv(data) {
    return this.http.post(environment.production ? environment.baseApiUrl + 'agent/env_change' : environment.baseApiUrlTest + 'agent/env_change', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  existsEmail(email) {
    return this.http.post(environment.production ? environment.baseApiUrl + 'agent/email_exist' : environment.baseApiUrlTest + 'agent/email_exist', email).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['status'];
        }
      )
    );
  }

  updateWebHook(data) {
    return this.http.post(environment.production ? environment.baseApiUrl + 'agent/update_hook' : environment.baseApiUrlTest + 'agent/update_hook', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }



}
