import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordValidators} from '../../shared/validators/password.validators';
import {EmailValidators} from '../../shared/validators/email.validators';
import {AgentService} from '../../shared/services/agent.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {AutocompleteService} from '../../shared/services/autocomplete.service';


@Component({
  selector: 'app-agent-modal',
  templateUrl: './agent-modal.component.html',
  styleUrls: ['./agent-modal.component.css']
})
export class AgentModalComponent implements OnInit {
  passwordType: string = 'password';
  passwordShown: boolean = false;
  icon: string = 'fa fa-eye-slash';
  form;
  formLive;
  status;
  isLive;
  agents;
  agentTestDetails;
  response;
  searching = false;
  searchFailed = false;
  results: any;
  clicked: boolean = false;

  @Output('newAgents') newAgent = new EventEmitter();

  constructor(fb: FormBuilder, private agentService: AgentService, private toastrService: ToastrService, private router: Router,
              private autocompleteService: AutocompleteService ) {
    this.form = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      phone_number: ['', [
              Validators.required,
              Validators.minLength(6)]],
      password: ['', [
            Validators.required,
            Validators.minLength(6),
            PasswordValidators.cannotContainSpace]
      ],
        cpassword: ['', Validators.required]
      ,
      address: ['', Validators.required],
      state_id: ['', Validators.required],
      country_id: ['', Validators.required]
    }, {
      validator: PasswordValidators.passwordsShouldMatch
    });
    this.formLive = fb.group({
      name: ['', Validators.required],
      password: ['', [
            Validators.required,
            Validators.minLength(6),
            // PasswordValidators.cannotContainSpace
      ]
      ],
      cpassword: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getAllAgents();
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchCountry(term)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )
  searchState = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchState(term, this.form.value.country_id)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )

  getSide() {
    if (localStorage.getItem('title') && localStorage.getItem('title') === 'Live') {
      return true;
    }
    return false;
  }

  addAgent() {
    this.clicked = true;
    this.agentService.addAgent(this.form.value).subscribe(
      data => {
          this.status = data;
          this.toastrService.success('New IMTO Created');
          this.closeModal();
          this.router.navigate(['agent']);
          this.clicked = false;
        },
      (error: Response) => {
              this.toastrService.error(error.statusText);
              console.log(error);
              this.clicked = false;
            }
    );
  }

  addAgentToLive() {
    this.clicked = true;
    this.agentService.getTestAgent(this.formLive.value.name).subscribe(
      response => {
        this.agentTestDetails = this.setResponseForPost(response, this.formLive.value);
        this.agentService.addAgentToLive(this.agentTestDetails).subscribe(
          data => {
            this.response = data;
            if (this.response.status) {
              this.toastrService.success(this.response.message);
              this.closeModal('closeModal1');
              this.router.navigate(['agent']);
              // Update Test to active_env live
              this.updateTestEnv(this.formLive.value.slug);
            } else {
              this.toastrService.error(this.response.message);
            }
          },
          (error: HttpResponse<any>) => {
            this.toastrService.error(error.statusText);
            console.log(error);
            this.clicked = false;
          }
        );
    }
  );
  }
  updateTestEnv(slug) {
    this.agentService.changeTestEnv({slug}).subscribe(
      data => {
        this.response = data;
        if (this.response.status) {
          this.toastrService.success(this.response.message);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
      }
    );
  }

  setResponseForPost(response, formValue) {
    const post = {
      name: response.name,
      email: response.email,
      password: formValue.password,
      country_id: response.country.id,
      state_id: response.state.id,
      address: response.address,
      phone_number: response.phone_number
    };
    return post;
  }

  closeModal(id = null) {
    const modalClose: HTMLElement = document.getElementById(id || 'closeModal') as HTMLElement;
    modalClose.click();
  }

  get name() {
    return this.form.get('name');
  }
  get nameLive() {
    return this.formLive.get('name');
  }
  get email() {
    return this.form.get('email');
  }
   get phone_number() {
    return this.form.get('phone_number');
  }
  get password() {
    return this.form.get('password');
  }
  get cpassword() {
    return this.form.get('cpassword');
  }
   get passwordLive() {
    return this.formLive.get('password');
  }
  get cpasswordLive() {
    return this.formLive.get('cpassword');
  }
  get state() {
    return this.form.get('state_id');
  }
  get country() {
    return this.form.get('country_id');
  }
  get address() {
    return this.form.get('address');
  }

  togglePassword() {
    if (this.passwordShown === true) {
      this.passwordShown = false;
      this.passwordType = 'password';
      this.icon = 'fa fa-eye-slash';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';
      this.icon = 'fa fa-eye';

    }
  }

  onClickGenerate() {
    const password = this.generatePassword();
    this.form.get('password').setValue(password);
    this.form.get('cpassword').setValue(password);
    }
  onClickGenerateLive() {
    const password = this.generatePassword();
    this.formLive.get('password').setValue(password);
    this.formLive.get('cpassword').setValue(password);

  }
  generatePassword(length = 8, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') {
    return Array(length)
      .fill('') // fill an empty will reduce memory usage
      .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
      .join('');
  }

  getAllAgents() {
    this.agentService.getTest().subscribe(
      data => this.agents = data
    );
  }


}
