import {Component, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const PARAMS = new HttpParams({
  fromObject: {}
});

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  // constructor(private http: HttpClient) {}
  url;
  constructor(private http: HttpClient ) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(environment.production ? environment.baseApiUrl + 'bank/get' : environment.baseApiUrlTest + 'bank/get', {params: PARAMS.set('bank', term)}).pipe(
        map(response => response['data'])
      );
  }

  searchCountry(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(environment.production ? environment.baseApiUrl + 'country/get' : environment.baseApiUrlTest + 'country/get', {params: PARAMS.set('country', term)}).pipe(
        map(response => response['data'])
      );
  }

  searchState(term: string, country: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(environment.production ? environment.baseApiUrl + 'state/get' : environment.baseApiUrlTest + 'state/get', {params: PARAMS.set('state', term).set('country', country)}).pipe(
        map(response => response['data'])
      );
  }
}
