import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TransactionService} from '../../shared/services/transaction.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit {
  transaction;
  constructor(private route: ActivatedRoute, private transactionService: TransactionService ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const transactionRef = params.get('transaction_ref');
      this.getTransaction(transactionRef);
    });
  }

  getTransaction(transactionRef) {
    this.transactionService.get(transactionRef).subscribe(
      // data => console.log(data)
      data => this.transaction = data
    );
  }
}
