import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {HttpResponse} from '@angular/common/http';



class ForgotPasswordModel {
  email: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  model = new ForgotPasswordModel();
  @ViewChild('forgotPasswordForm', {static: false}) form;
  errorMessage: string;
  successMessage: string;
  clicked: boolean = false;
  constructor(private authenticationService: AuthenticationService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {

  }

  onSubmit() {
    this.clicked = true;
    this.authenticationService.forgotPassword(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.successMessage = 'An email has been sent to you.'
        this.toastr.success('', this.successMessage);
        return this.router.navigate(['/login']);
      },
  (error: HttpResponse<any>)  => {
            this.errorMessage =  error.statusText;
            this.toastr.error('', this.errorMessage);
            console.log(error);
            this.clicked = false;
      });
  }

}
