import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AgentService} from '../../shared/services/agent.service';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.css']
})
export class AgentDetailsComponent implements OnInit {
id;
data;
  transactions;
  config: any;
  constructor(private route: ActivatedRoute, private agentService: AgentService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      this.getAgent(slug);
      this.getTransaction(slug);
    });
  }

  getAgent(id) {
    this.agentService.getAgent(id).subscribe(
      data => this.data = data
    );
  }
  getTransaction(agentId) {
    this.agentService.getAgentTransaction(agentId).subscribe(
      data => {
        this.transactions = data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.transactions.length
        };
      }
    );
  }


  pageChanged(event) {
    this.config.currentPage = event;
  }

}
