import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {forkJoin, Observable} from 'rxjs';
import {map, catchError } from 'rxjs/operators';
import { AuthUserHelper } from '../helpers/auth-user.helper';

@Injectable()
export class AuthenticationService {

  url;
  sideUrl
  responseOne;
  responseTwo;
  constructor(private http: HttpClient, private authUser: AuthUserHelper, private router : Router) {
    this.url = environment.baseApiUrl;
    this.sideUrl = this.getSide();
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url');
    }
  }
  getSide() {
    if (localStorage.getItem('title') && localStorage.getItem('title') === 'Test') {
      // return 'http://localhost:8080/v1/';
      return 'https://api.icashremit.com/v1/';
    }
    return 'https://test.icashremit.com/v1/';
    // return 'http://localhost:8000/v1/';
  }


  authenticate(data) {
    this.responseOne = this.http.post(environment.production ? environment.baseApiUrl  + 'auth': environment.baseApiUrlTest + 'auth', data).pipe(
      map((response: HttpResponse<any>) => {
        const token  = response['token'];
        this.authUser.setToken(token);
        this.authUser.setTokenTwoo(token);
      })
    );
    // this.responseTwo = this.http.post(this.sideUrl + 'admin/auth', data).pipe(
    //   map((response: HttpResponse<any>) => {
    //     const token  = response['token'];
    //     this.authUser.setTokenTwo(token);
    //   })
    // );
    // return forkJoin([this.responseOne, this.responseTwo]);
    return this.responseOne;
  }


  forgotPassword(data) {
    this.responseOne = this.http.post(environment.production ? environment.baseApiUrl  + 'forgot-password': environment.baseApiUrlTest + 'forgot-password', data)
    // this.responseTwo = this.http.post(this.sideUrl  + 'admin/forgot-password', data)


    // this.responseOne = this.http.post('http://localhost:8000/v1/admin/forgot-password', data)
    
  //  return forkJoin([this.responseOne, this.responseTwo]);
     return this.responseOne;
  }


  resetPassword(data, token) {
    this.responseOne = this.http.post(environment.production ? environment.baseApiUrl + `reset-password/${token}` : environment.baseApiUrlTest + `reset-password/${token}`, data)
    // this.responseTwo = this.http.post(this.sideUrl + `admin/reset-password/${token}`, data)

    //this.responseOne = this.http.post(`http://localhost:8000/v1/admin/reset-password/${token}`, data)

    //  return forkJoin([this.responseOne, this.responseTwo]);
  return this.responseOne;
  }
  
  logout() {
    this.authUser.removeToken();
    this.router.navigate(['/login']);
  }

}
